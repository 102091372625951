@tailwind base;
@tailwind components;


/*:root {
  --p: 247 93% 52%;
  --pf: 247 85% 48%;
  --pc: 0 0% 100%;
  --s: 0 0% 100%;
  --sf: 0 0% 97%;
  --sc: 247 93% 52%;
  --a: 172 34% 48%;
  --af: 173 33% 37%;
  --ac: 0 0% 100%;
  --n: 207 6% 28%;
  --nf: 210 5% 21%;
  --nc: 0 0% 100%;
  --b1: 0 0% 100%;
  --b2: 210 25% 98%;
  --b3: 210 8% 62%;
  --bc: 207 6% 28%;
  --in: 196 90% 32%;
  --su: 172 34% 48%;
  --wa: 39 100% 50%;
  --er: 0 68% 49%;

}*/

h1 {
  @apply font-bold mt-0;
  font-size: 22px;
  font-family: 'Roboto Slab', serif;
}
h2 {
  @apply font-normal mt-0;
  font-size: 20px;
  font-family: 'Roboto Slab', serif;
}
h3 {
  @apply font-normal mt-0;
  font-size: 18px;
  font-family: 'Roboto Slab', serif;
}
h4 {
  @apply font-medium mt-0;
  font-size: 16px;
  font-family: 'Roboto Slab', serif;
}
h5 {
  @apply font-normal mt-0;
  font-size: 14px;
  font-family: 'Roboto Slab', serif;
}
h6 {
  @apply font-normal mt-0;
  font-size: 12px;
  font-family: 'Roboto', serif;
}

p {
  font-size: 14px;
  font-family: 'Roboto', serif;
}

.input, .select, .textarea {
  @apply shadow-md;
}

.btn:not(.btn-link, .btn-ghost) {
  @apply rounded-full shadow-lg;
}

.bg-primary .badge,
.bg-primary .label-text
{
  color: hsl(var(--nc));
}

.bg-primary .checkbox-mark {
  border: 1px solid hsl(var(--nc))
}

.header--layout__small {
  @apply flex items-center justify-between w-full p-3 h-16 bg-primary text-primary-content;
}

.bg-pattern {
  background-size: 100% auto;
  background-repeat: no-repeat;
  background-position: bottom;
}

.swal2-confirm .swal2-styled {
  background-color: hsl(var(--s));
}

.downshift-options {
  @apply rounded-md input absolute z-10 left-0 right-0 top-16 shadow-lg 
}

.downshift-options-focus {
  @apply bg-gray-200
}

.downshift-options-selected {
  @apply font-bold
}

.invalid-feedback {
  @apply mt-1 text-xs text-red-500
}
.invalid-tooltip {
  @apply absolute z-10 hidden w-4 font-normal leading-normal text-white rounded p-2 bg-red-700
}
.is-invalid {
  @apply border border-red-500
}
.is-invalid:focus {
  @apply border border-red-500 ring
}

.txt {
  @apply text-base font-light leading-relaxed mt-0 mb-4
}
.txt-sm {
  @apply font-normal leading-normal mt-0 mb-4 
}
.txt-lg {
  @apply text-lg font-light leading-relaxed mt-6 mb-4
}

.card {
  @apply rounded-xl
}

.card.white {
  @apply bg-white shadow-lg
}

.scrolling-wrapper {
  @apply flex flex-nowrap	overflow-x-auto
}
  /* Hide scrollbar for IE, Edge and Firefox
  scrollbar-width: none;  /* Firefox */  

  /* Hide scrollbar for Chrome, Safari and Opera */

.scrolling-wrapper::-webkit-scrollbar {
  display: none;
}

.scrolling-wrapper .card {
  justify-content: center;
  flex: 0 0 auto;
  align-items: center;
  display: flex;
  flex-direction: column;
}


/* customs styles */

.sidebar--user-nfo {
  @apply flex border-b pb-5 mb-5 mt-12;
}

.avatar {
  @apply rounded-full bg-base-100 overflow-hidden flex items-center text-center
}

.input {
  @apply text-base-content font-bold;
}

input::placeholder {
  @apply font-normal;
}

.select {
  @apply text-gray-600;
}

.menu {
  @apply w-full;
}


@tailwind utilities;