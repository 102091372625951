@import url('https://fonts.googleapis.com/css2?family=Roboto&family=Roboto+Condensed:wght@400;700&family=Roboto+Slab:wght@400;700&display=swap');


html, body, #root {
  height: 100%;
}

body {
  font-family: 'Roboto', sans-serif;
}

input[type="radio"]:checked + label span {
  background-color: #718096; /* bg-gray-600 */
  /* box-shadow: 0px 0px 0px 1px white inset; */
}

input[type="radio"]:checked + label{
  /* color: #3490DC; //text-blue */
}

.invalid-shadow:focus {
  box-shadow: 0 0 0 0.2rem rgba(220,53,69,.25)
}
.invalid-shadow-focus {
  box-shadow: 0 0 0 0.2rem rgba(220,53,69,.25)
}
.Toastify__toast.Toastify__toast--success,
.Toastify__toast.Toastify__toast--info,
.Toastify__toast.Toastify__toast--warn,
.Toastify__toast.Toastify__toast--error {
  border-radius: 5px;
}

input:checked + svg {
  display: block;
}
.animate__animated.animate__fadeIn, .animate__fadeOut{
	--animate-duration: 0.25s;
}

/* Chrome, Safari and Opera */
/* .no-scrollbar::-webkit-scrollbar {
  display: none;
} */



.scrollbar::-webkit-scrollbar {
  width: 10px;               /* width of the entire scrollbar */
}

.scrollbar::-webkit-scrollbar-track {
  background: rgb(245, 245, 245);        /* color of the tracking area */
}

.scrollbar::-webkit-scrollbar-thumb {
  background-color: rgb(199, 199, 199);    /* color of the scroll thumb */
  border-radius: 20px;       /* roundness of the scroll thumb */
  border: 3px solid rgb(245, 245, 245);   /* creates padding around scroll thumb */
}

/* .fade-enter {
  opacity: 0;
  z-index: 1;
}

.fade-enter.fade-enter-active {
  opacity: 1;
  transition: opacity 150ms ease-in;
} */
.image-message {
  max-width: 100%;
}